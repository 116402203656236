<template>
    <div>
        <vue-title title="Грузы | ТОРГОВАЯ ПЛОЩАДКА"></vue-title>
        <Header :routes="headerRoutes.defaultsoutes"/>
        <div class="container py-4">
            <div class="text-center">
                <h1 class="page-title mb-4">Грузы <span class="text-muted">| ТОРГОВАЯ ПЛОЩАДКА</span></h1>
            </div>
            <div class="row mb-4">
                <div class="col-12 col-sm-6 col-md-9 d-none d-lg-block" v-if="!routeClassifier">
                    <DxLoadIndicator
                            id="small-indicator"
                            :height="20"
                            :width="20"
                    />
                    Загрузка
                </div>
                <div class="col-12 col-sm-6 col-lg-9 d-none d-lg-block" v-if="routeClassifier">
                    <strong v-for="routeClassifierItem in routeClassifier" :key="routeClassifierItem.routeClassifierId"
                            class="mr-3 search-item"
                            :class="{ 'search-item-active': (routeClassifierItem.routeClassifierId == routeClassifierId || (routeClassifierId == '' && routeClassifierItem.shortName == 'Все')) && !cargoLotId, 'text-muted': routeClassifierItem.count == 0, 'text-primary': routeClassifierItem.count > 0 }"
                            data-toggle="tooltip" data-html="true"
                            :title="'<b>'+routeClassifierItem.name+'</b><br>'+(routeClassifierItem.note != undefined ? routeClassifierItem.note : '')"
                            @click="selectRouteClassifierItem(routeClassifierItem.routeClassifierId, routeClassifierItem.shortName, routeClassifierItem.count)"
                    >{{ routeClassifierItem.shortName }} ({{ routeClassifierItem.count }})</strong>
                </div>
                <div class="col-6 col-sm-6 d-block d-lg-none" v-if="routeClassifier">
                    <div class="dropdown">
                        <button class="btn btn-link dropdown-toggle text-secondary py-0" type="button"
                                id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                            <strong>{{ routeClassifierName }} ({{ routeClassifierCount }})</strong>
                        </button>
                        <div class="dropdown-menu shadow" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" href="#"
                               v-for="routeClassifierItem in routeClassifier"
                               :key="routeClassifierItem.routeClassifierId"
                               @click="selectRouteClassifierItem(routeClassifierItem.routeClassifierId, routeClassifierItem.shortName, routeClassifierItem.count)"
                            ><strong>{{ routeClassifierItem.shortName }} ({{ routeClassifierItem.count }})</strong></a>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-sm-6 d-block d-lg-none text-right" v-if="groupsCode">
                    <div class="dropdown">
                        <button class="btn btn-link dropdown-toggle text-secondary py-0" type="button"
                                id="dropdownMenuButtonG" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                            <strong>{{ groupCodeName }}{{ groupCode == '' ? '' : ' ('+groupCodeCount+')' }}</strong>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right shadow" aria-labelledby="dropdownMenuButtonG">
                            <a class="dropdown-item" href="#"
                               v-for="groupsCodeItem in groupsCode" :key="groupsCodeItem.groupCode"
                               @click="selectGroupsCodeItem(groupsCodeItem.groupCode, groupsCodeItem.groupName, groupsCodeItem.cargoCount)"
                            ><strong>{{ groupsCodeItem.groupName }} ({{ groupsCodeItem.cargoCount }})</strong></a>
                        </div>
                    </div>
                </div>
                <div class="col-12 pt-3 d-none d-lg-block" v-if="!groupsCode">
                    <DxLoadIndicator
                        id="small-indicator-groupsCode"
                        :height="20"
                        :width="20"
                    />
                    Загрузка
                </div>
                <div class="col-12 pt-3 d-none d-lg-block" v-if="groupsCode">
                    <span v-for="groupsCodeItem in groupsCode" :key="groupsCodeItem.groupCode"
                          class="mr-3 search-item"
                          :class="{ 'search-item-active': groupsCodeItem.groupCode == groupCode && !cargoLotId, 'text-muted': groupsCodeItem.cargoCount == 0, 'text-primary': groupsCodeItem.cargoCount > 0 }"
                          @click="selectGroupsCodeItem(groupsCodeItem.groupCode, groupsCodeItem.groupName, groupsCodeItem.cargoCount)"
                    >{{ groupsCodeItem.groupName }} ({{ groupsCodeItem.cargoCount }})</span>
                </div>
            </div>
            <div class="mb-3">
                <div class="btn-group btn-block" role="group">
                    <button type="button" class="btn border-right-0"
                            data-toggle="collapse" data-target="#collapseAdvancedSearch" aria-expanded="false" aria-controls="collapseAdvancedSearch"
                            style="border-bottom-left-radius: 0;"
                            @click="openAdvancedSearch()"
                            :class="{
                                'btn-outline-primary': !isSetSearch,
                                'btn-outline-warning text-black': isSetSearch,
                                }"
                    ><i class="fas fa-search mr-2"></i></button>
                    <button class="btn btn-block text-left shadow-sm d-flex justify-content-between border-left-0"
                            @click="openAdvancedSearch()"
                            :class="{
                                'btn-outline-primary': !isSetSearch,
                                'btn-outline-warning text-black': isSetSearch,
                                }"
                            type="button" data-toggle="collapse" data-target="#collapseAdvancedSearch" aria-expanded="false" aria-controls="collapseAdvancedSearch"
                            style="border-bottom-right-radius: 0; border-bottom-left-radius: 0;"
                    >
                        <strong>Расширенный поиск{{ isSetSearch ? ' (используется)' : '' }}</strong><i class="fas fa-chevron-up pt-1" id="advancedSearchChevron"></i>
                    </button>
                </div>
                <div class="collapse" id="collapseAdvancedSearch">
                    <div class="card card-body shadow-sm" style="border-radius: 0;">
                        <form class="advanced-search-form" @submit.prevent="onSubmitAdvancedSearch">
                            <DxForm
                                id="formAdvancedSearch"
                                :col-count="1"
                                :form-data="advancedSearch"
                                :label-location="labelLocation"
                                :read-only="false"
                                :show-colon-after-label="true"
                                :show-validation-summary="true"
                                :disabled="!cargos"
                            >
                                <DxGroupItem :col-count="2">
                                    <DxSimpleItem
                                        data-field="fromLocalityId"
                                        :editor-options="fromOptions"
                                        editor-type="dxSelectBox"
                                    >
                                        <DxLabel :visible="false"/>
                                    </DxSimpleItem>
                                    <DxSimpleItem
                                        data-field="toLocalityId"
                                        :editor-options="toOptions"
                                        editor-type="dxSelectBox"
                                    >
                                        <DxLabel :visible="false"/>
                                    </DxSimpleItem>
                                </DxGroupItem>
                                <DxGroupItem :col-count="5">
                                    <DxColCountByScreen :xs="1" :sm="2"/>
                                    <DxSimpleItem data-field="pickupDate" editor-type="dxDateBox" :editor-options="{value: advancedSearch.pickupDate, type: 'date', displayFormat: 'dd.MM.yyyy'}">
                                        <DxLabel text="Дата погрузки"/>
                                    </DxSimpleItem>
                                    <DxSimpleItem
                                        :editor-options="rollingStockTypeOptions"
                                        data-field="rollingStockTypeId"
                                        editor-type="dxSelectBox"
                                    >
                                        <DxLabel text="Вид ТС"/>
                                    </DxSimpleItem>
                                    <DxGroupItem :col-count="2" cssClass="carryingGroup">
                                        <DxLabel text="Грузоподъемность (т.)"/>
                                        <DxSimpleItem data-field="weightMin" :editor-options="weightMinOptions">
                                            <DxLabel :visible="false"/>
                                        </DxSimpleItem>
                                        <DxSimpleItem data-field="weightMax" :editor-options="weightMaxOptions">
                                            <DxLabel :visible="false"/>
                                        </DxSimpleItem>
                                    </DxGroupItem>
                                    <DxGroupItem :col-count="2" cssClass="volumeGroup">
                                        <DxLabel text="Объем (куб.м)"/>
                                        <DxSimpleItem data-field="volumeMin" :editor-options="volumeMinOptions">
                                            <DxLabel :visible="false"/>
                                        </DxSimpleItem>
                                        <DxSimpleItem data-field="volumeMax" :editor-options="volumeMaxOptions">
                                            <DxLabel :visible="false"/>
                                        </DxSimpleItem>
                                    </DxGroupItem>
                                    <!--                                        <DxSimpleItem-->
                                    <!--                                            :editor-options="{value: advancedSearch.loadingType, searchEnabled: true, items: loadingTypes, showClearButton: true, acceptCustomValue: true, placeholder: 'Свой способ...'}"-->
                                    <!--                                            data-field="loadingType"-->
                                    <!--                                            editor-type="dxSelectBox"-->
                                    <!--                                        >-->
                                    <!--                                            <DxLabel text="Способ погрузки"/>-->
                                    <!--                                        </DxSimpleItem>-->
                                    <DxSimpleItem data-field="acn" :editor-options="acnOptions">
                                        <DxLabel text="Номер перевозки"/>
                                    </DxSimpleItem>
                                </DxGroupItem>
                                <DxGroupItem :col-count="10">
                                    <DxEmptyItem :col-span="isSetSearch ? 7 : 9" />
                                    <DxButtonItem
                                        v-if="isSetSearch"
                                        cssClass="pt-3"
                                        :button-options="buttonSearchResetOptions"
                                        :col-span="2"
                                    />
                                    <DxButtonItem
                                        cssClass="pt-3"
                                        :button-options="buttonSearchOptions"
                                    />
                                </DxGroupItem>
                            </DxForm>
                        </form>
                    </div>
                </div>
                <div class="card card-body bg-light shadow-sm px-2 py-2" style="border-top-right-radius: 0; border-top-left-radius: 0;">
                    <p class="mb-0 text-muted small">
                        Найдено: {{ cargosTotals }}
                    </p>
                </div>
            </div>

            <div class="text-left mb-3 d-inline-flex">
                <div class="pt-2">Сортировать по времени:</div>
                <div class="dropdown">
                    <button class="btn btn-link dropdown-toggle text-primary" type="button" id="dropdownMenuButtonSort"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span v-html="sortByName"></span>
                    </button>
                    <div class="dropdown-menu shadow" aria-labelledby="dropdownMenuButtonSort">
                        <a class="dropdown-item" href="#" @click="sortBy('подачи авто', 'pickupDate', 'fas fa-sort-amount-up', false)"><i
                                class="fas fa-sort-amount-up"></i> подачи авто</a>
                        <a class="dropdown-item" href="#" @click="sortBy('подачи авто', 'pickupDate', 'fas fa-sort-amount-down', true)"><i
                                class="fas fa-sort-amount-down"></i> подачи авто</a>
                        <a class="dropdown-item" href="#" @click="sortBy('регистрации', 'registrationTime', 'fas fa-sort-amount-up', false)"><i
                                class="fas fa-sort-amount-up"></i> регистрации</a>
                        <a class="dropdown-item" href="#" @click="sortBy('регистрации', 'registrationTime', 'fas fa-sort-amount-down', true)"><i
                                class="fas fa-sort-amount-down"></i> регистрации</a>
                    </div>
                </div>
            </div>
            <div v-if="errorMessage" class="alert alert-danger mb-3" role="alert">
                <strong>Внимание!</strong> {{ errorMessage }}
            </div>
            <div v-if="!cargos" class="mb-3">
                <DxLoadIndicator
                        id="medium-indicator"
                        :height="20"
                        :width="20"
                />
                Загрузка
            </div>
            <div v-if="cargos">
                <div v-if="cargos.totals == 0">
                    <strong><i class="fas fa-search mr-2"></i>Ничего не найдено.</strong>
                </div>
                <div class="row" v-if="cargos.totals > 0">
                    <div class="col-12 col-lg-6 mb-4" v-for="cargoItem in cargos.items" :key="cargoItem.cargoLotId">
                        <div class="card h-100 shadow-sm">
                            <div class="bg-primary" style="border-top-left-radius: 0.25rem; border-top-right-radius: 0.25rem;">
                                <div class="row">
                                    <div class="col-4 text-left text-white py-1">
                                        <small class="mb-2 pl-3">{{ cargoItem.cargoLotNumber }}</small>
                                    </div>
                                    <div class="col-4 text-center p-0">
                                        <small class="bg-white mb-2 px-2 pb-1" style="border-bottom-right-radius: 0.25rem; padding-top: 0.3rem; border-bottom-left-radius: 0.25rem;"><span v-html="getStatus(cargoItem)"></span></small>
                                    </div>
                                    <div class="col-4 text-right text-white py-1">
                                        <small class="mb-2 pr-3">{{ cargoItem.pickupDate | moment("DD.MM.YYYY") }}</small>
                                    </div>
                                </div>
                                <div class="text-white px-3 pt-0 pb-2">
                                    {{ cargoItem.cargo.descriptionOfCargo }}
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-2 col-sm-1">
                                        <i class="fas fa-map-marked-alt font-20 text-muted"></i>
                                    </div>
                                    <div class="col-10 col-sm-11">
                                        <span class="text-primary link-revert" @click="openRoute(cargoItem.cargoLotId)">{{ cargoItem.routeCaption }}</span>
                                        <div class="modal fade" :id="'routeModal'+cargoItem.cargoLotId" tabindex="-1"
                                             :aria-labelledby="'routeModalLabel'+cargoItem.cargoLotId"
                                             aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title"
                                                            :id="'routeModalLabel'+cargoItem.cargoLotId">Информация по
                                                            маршруту</h5>
                                                        <button type="button" class="close" data-dismiss="modal"
                                                                aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <strong>Маршрут:</strong>
                                                        <p class="font-12">{{ cargoItem.routeCaption }}</p>
                                                        <strong>Адреса:</strong>
                                                        <div v-if="!selectRoute" class="mb-3">
                                                            <DxLoadIndicator
                                                                id="medium-indicator-routes"
                                                                :height="20"
                                                                :width="20"
                                                            />
                                                            Загрузка
                                                        </div>
                                                        <table v-if="selectRoute" class="table table-borderless font-12">
                                                            <tbody>
                                                            <tr v-for="(route, index) in selectRoute"
                                                                :key="route.routePointId">
                                                                <td class="pb-0">
                                                                    <div class="row">
                                                                        <div class="col-12 pb-3 d-flex align-items-center"
                                                                             v-if="index > 0">
                                                                            <i class="fas fa-long-arrow-alt-down text-yellow"
                                                                               style="font-size: 25px"></i><strong
                                                                            class="text-primary ml-3"
                                                                            v-if="route.distance > 0">{{
                                                                                route.distance }} км</strong>
                                                                        </div>
                                                                        <div class="col-3 pr-0">
                                                                            <div v-if="route.operationType == 'rptLoading'"
                                                                                 class="h-100 d-flex align-items-center">
                                                                                <i class="fas fa-arrow-right pr-1 text-danger"
                                                                                   style="font-size: 20px"></i><i
                                                                                    class="fas fa-truck-loading text-danger"
                                                                                    style="font-size: 25px"></i>
                                                                            </div>
                                                                            <div v-if="route.operationType == 'rtpUnloading'"
                                                                                 class="h-100 d-flex align-items-center">
                                                                                <i class="fas fa-arrow-left pr-1 text-secondary"
                                                                                   style="font-size: 20px"></i><i
                                                                                    class="fas fa-truck-loading text-secondary"
                                                                                    style="font-size: 25px"></i>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-9 pl-0">
                                                                            <span v-if="isViewTime(route.operationTime)">{{ route.operationTime | moment("DD.MM.YYYY HH:mm") }}<br>{{ route.address.localityDisplay }}</span>
                                                                            <span v-if="!isViewTime(route.operationTime)">{{ route.operationTime | moment("DD.MM.YYYY") }}<br>{{ route.address.localityDisplay }}</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-secondary mr-2"
                                                                data-dismiss="modal">Закрыть
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-2 col-sm-1">
                                        <i class="fas fa-truck-moving font-20 text-muted"></i>
                                    </div>
                                    <div class="col-10 col-sm-11">
                                        {{ cargoItem.cargo.rollingStockRequestDisplay }}
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-2 col-sm-1">
                                        <i class="fas fa-exclamation-triangle font-20 text-warning"></i>
                                    </div>
                                    <div class="col-10 col-sm-11">
                                        Особые условия: {{ cargoItem.cargo.additionCarriageTerm }}
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="row">
                                    <div class="col-6 col-sm-4">
                                        <button type="button"
                                                class="btn btn-outline-primary btn-sm" @click="openOffers(cargoItem.cargoLotId)">
                                            <span class="small">ВСЕ ПРЕДЛОЖЕНИЯ</span>
                                        </button>
                                        <div class="modal fade" :id="'offersModal'+cargoItem.cargoLotId" tabindex="-1"
                                             :aria-labelledby="'offersModalLabel'+cargoItem.cargoLotId"
                                             aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title"
                                                            :id="'offersModalLabel'+cargoItem.cargoLotId">
                                                            Предложения</h5>
                                                        <button type="button" class="close" data-dismiss="modal"
                                                                aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <div v-if="!selectOffers" class="mb-3">
                                                            <DxLoadIndicator
                                                                id="medium-indicator-offers"
                                                                :height="20"
                                                                :width="20"
                                                            />
                                                            Загрузка
                                                        </div>
                                                        <div v-if="selectOffers && selectOffers.length == 0">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    На данный момент предложений нет.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <table v-if="selectOffers && selectOffers.length > 0" class="table mb-0 font-12">
                                                            <tbody>
                                                            <tr v-for="(offer, index) in selectOffers"
                                                                :key="offer.cargoOfferId">
                                                                <td style="border: 0px; width: 5px;" class="pl-1 pr-0"><i v-if="offer.status == 'oisApproved'" class="fas fa-check-circle text-success"></i></td>
                                                                <td style="border: 0px; width: 10px;" class="pr-0">{{ index+1 }}:</td>
                                                                <td style="border: 0px; width: 130px">{{
                                                                    offer.timeCreate | moment("DD.MM.YYYY HH:mm") }}
                                                                </td>
                                                                <td style="border: 0px">{{
                                                                    offer.rollingStockUnitTypeName }}
                                                                </td>
                                                                <td class="text-right"
                                                                    style="border: 0px; width: 120px"><strong>{{
                                                                    offer.amount | formatMoney }}  {{ offer.currencyShortName }}</strong></td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-secondary mr-2"
                                                                data-dismiss="modal">Закрыть
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-4 text-right text-sm-center">
                                        <div v-if="!cargoItem.agentOffer">
                                            <button type="button" class="btn btn-outline-primary btn-sm"
                                                    data-toggle="modal" data-target="#noLoginModal" v-if="!isLogged">
                                                <i class="fas fa-file-invoice-dollar font-18 mr-1"></i><span
                                                    class="small">ПРЕДЛОЖИТЬ ЦЕНУ</span>
                                            </button>
                                            <button type="button" class="btn btn-outline-primary btn-sm"
                                                    @click="openAddOfferModal(cargoItem.cargoLotId, cargoItem.rollingStockTypeId)"
                                                    v-if="isLogged">
                                                <i class="fas fa-file-invoice-dollar font-18 mr-1"></i><span
                                                    class="small">ПРЕДЛОЖИТЬ ЦЕНУ</span>
                                            </button>
                                            <div class="modal fade" :id="'addOfferModal'+cargoItem.cargoLotId"
                                                 tabindex="-1"
                                                 :aria-labelledby="'addOfferModalLabel'+cargoItem.cargoLotId"
                                                 aria-hidden="true">
                                                <div class="modal-dialog modal-lg">
                                                    <div class="modal-content">
                                                        <div class="modal-header text-left">
                                                            <h5 class="modal-title"
                                                                :id="'addOfferModalLabel'+cargoItem.cargoLotId">Добавить
                                                                предложение: <strong>{{ cargoItem.cargoLotNumber
                                                                    }}</strong></h5>
                                                            <button type="button" class="close" data-dismiss="modal"
                                                                    aria-label="Close">
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                        </div>
                                                        <div class="modal-body">
                                                            <div v-if="errorMessageAddOffer"
                                                                 class="alert alert-danger mb-3" role="alert">
                                                                <strong>Внимание!</strong> {{ errorMessageAddOffer }}
                                                            </div>
                                                            <DxForm
                                                                    :col-count="1"
                                                                    :form-data="offerNew"
                                                                    :label-location="labelLocation"
                                                                    :ref="'addOfferFormRef'+cargoItem.cargoLotId"
                                                                    :show-colon-after-label="true"
                                                                    :show-validation-summary="true"
                                                                    :disabled="isSendOffer"
                                                            >
                                                                <DxGroupItem :colCount="3">
                                                                    <DxColCountByScreen :xs="1" :sm="1"/>
                                                                    <DxSimpleItem
                                                                            :editor-options="{value: offerNew.rollingStockUnitTypeId, searchEnabled: true, dataSource: storeRollingStockUnitTypes, displayExpr: 'rollingStockUnitTypeName', valueExpr: 'rollingStockUnitTypeId', showClearButton: true}"
                                                                            data-field="rollingStockUnitTypeId"
                                                                            editor-type="dxSelectBox"
                                                                    >
                                                                        <DxLabel text="Тип ПС"/>
                                                                        <DxRequiredRule message="Укажите Тип ПС"/>
                                                                    </DxSimpleItem>
                                                                    <DxSimpleItem
                                                                            :editor-options="{value: offerNew.amount}"
                                                                            data-field="amount"
                                                                            editor-type="dxNumberBox"
                                                                    >
                                                                        <DxLabel text="Ваше предложение"/>
                                                                        <DxRequiredRule/>
                                                                    </DxSimpleItem>
                                                                    <DxSimpleItem
                                                                            :editor-options="{value: offerNew.currencyId, items: currencys, displayExpr: 'сurrencyName', valueExpr: 'сurrencyId', showClearButton: true, searchEnabled: true}"
                                                                            data-field="currencyId"
                                                                            editor-type="dxSelectBox"
                                                                    >
                                                                        <DxLabel text="Валюта"/>
                                                                        <DxRequiredRule message="Укажите Валюту"/>
                                                                    </DxSimpleItem>
                                                                </DxGroupItem>
                                                                <DxSimpleItem
                                                                    :editor-options="{value: offerNew.note}"
                                                                    data-field="note"
                                                                    editor-type="dxTextBox"
                                                                >
                                                                    <DxLabel text="Комментарий"/>
                                                                </DxSimpleItem>
                                                            </DxForm>
                                                        </div>
                                                        <div class="modal-footer">
                                                            <button type="button" class="btn btn-primary"
                                                                    @click="AddOffer(cargoItem.cargoLotId)"
                                                                    :disabled="isSendOffer">Сохранить
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="cargoItem.agentOffer">
                                            <button type="button" class="btn btn-outline-primary btn-sm"
                                                    @click="openEditOfferModal(cargoItem.cargoLotId, cargoItem.rollingStockTypeId, cargoItem.agentOffer)">
                                                <i class="fas fa-file-invoice-dollar font-18 mr-1"></i><span
                                                    class="small">ИЗМЕНИТЬ ЦЕНУ</span>
                                            </button>
                                            <div class="modal fade" :id="'editOfferModal'+cargoItem.cargoLotId"
                                                 tabindex="-1"
                                                 :aria-labelledby="'editOfferModalLabel'+cargoItem.cargoLotId"
                                                 aria-hidden="true">
                                                <div class="modal-dialog modal-lg">
                                                    <div class="modal-content">
                                                        <div class="modal-header text-left">
                                                            <h5 class="modal-title"
                                                                :id="'editOfferModalLabel'+cargoItem.cargoLotId">
                                                                Изменить предложение: <strong>{{
                                                                cargoItem.cargoLotNumber }}</strong></h5>
                                                            <button type="button" class="close" data-dismiss="modal"
                                                                    aria-label="Close">
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                        </div>
                                                        <div class="modal-body">
                                                            <div v-if="errorMessageEditOffer"
                                                                 class="alert alert-danger mb-3" role="alert">
                                                                <strong>Внимание!</strong> {{ errorMessageEditOffer }}
                                                            </div>
                                                            <DxForm
                                                                    :col-count="1"
                                                                    :form-data="offerEdit"
                                                                    :label-location="labelLocation"
                                                                    :ref="'editOfferFormRef'+cargoItem.cargoLotId"
                                                                    :show-colon-after-label="true"
                                                                    :show-validation-summary="true"
                                                                    :disabled="isSendOffer"
                                                            >
                                                                <DxGroupItem :colCount="3">
                                                                    <DxColCountByScreen :xs="1" :sm="1"/>
                                                                    <DxSimpleItem
                                                                            :editor-options="{
                                                                              value: offerEdit.rollingStockUnitTypeId,
                                                                              dataSource: storeRollingStockUnitTypes,
                                                                              displayExpr: 'rollingStockUnitTypeName',
                                                                              valueExpr: 'rollingStockUnitTypeId',
                                                                              showClearButton: true,
                                                                              showDataBeforeSearch: false
                                                                              }"
                                                                            data-field="rollingStockUnitTypeId"
                                                                            editor-type="dxSelectBox"
                                                                    >
                                                                        <DxLabel text="Тип ПС"/>
                                                                        <DxRequiredRule message="Укажите Тип ПС"/>
                                                                    </DxSimpleItem>
                                                                    <DxSimpleItem
                                                                            :editor-options="{value: offerEdit.amount}"
                                                                            data-field="amount"
                                                                            editor-type="dxNumberBox"
                                                                    >
                                                                        <DxLabel text="Новое предложение"/>
                                                                        <DxRequiredRule/>
                                                                    </DxSimpleItem>
                                                                    <DxSimpleItem
                                                                            :editor-options="{value: offerEdit.currencyId, items: currencys, displayExpr: 'сurrencyName', valueExpr: 'сurrencyId', showClearButton: true, searchEnabled: true}"
                                                                            data-field="currencyId"
                                                                            editor-type="dxSelectBox"
                                                                    >
                                                                        <DxLabel text="Валюта"/>
                                                                        <DxRequiredRule message="Укажите Валюту"/>
                                                                    </DxSimpleItem>
                                                                </DxGroupItem>
                                                                <DxSimpleItem
                                                                    :editor-options="{value: offerEdit.note}"
                                                                    data-field="note"
                                                                    editor-type="dxTextBox"
                                                                >
                                                                    <DxLabel text="Комментарий"/>
                                                                </DxSimpleItem>
                                                            </DxForm>
                                                        </div>
                                                        <div class="modal-footer d-flex justify-content-between">
                                                            <button type="button" class="btn btn-danger"
                                                                    @click="DelOffer(cargoItem.cargoLotId, cargoItem.agentOffer.cargoOfferId)">
                                                                Удалить
                                                            </button>
                                                            <div>
                                                                <button type="button" class="btn btn-primary"
                                                                        @click="EditOffer(cargoItem.cargoLotId, cargoItem.agentOffer.cargoOfferId)"
                                                                        :disabled="isSendOffer">Сохранить
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="!cargoItem.agentOffer" class="col-12 col-sm-4 pt-2 pt-sm-1 text-right">
                                        <span class="small text-muted">НЕТ ПРЕДЛОЖЕНИЯ</span>
                                    </div>
                                    <div v-if="cargoItem.agentOffer" class="col-12 col-sm-4 pt-2 pt-sm-1 text-right">
                                        <span class="text-muted">{{ cargoItem.agentOffer.amount | formatMoney }} {{ cargoItem.agentOffer.currencyShortName }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-3">
                <paginate
                        v-model="page"
                        :page-count="cargos ? Math.ceil(cargos.totals / take) : 0"
                        :click-handler="clickPagination"
                        :prev-text="'  '"
                        :next-text="'  '"
                        :container-class="'pagination justify-content-center'"
                        :page-class="'page-item'"
                        :page-link-class="'page-link'"
                        :prev-class="'page-item'"
                        :next-class="'page-item'"
                        :prev-link-class="'page-link'"
                        :next-link-class="'page-link'"
                        :active-class="'active'">
                </paginate>
            </div>
        </div>
        <Footer/>
        <!-- Modals -->
        <div class="modal fade" id="noLoginModal" tabindex="-1" aria-labelledby="noLoginModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="noLoginModalLabel">Вы не авторизованы</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" v-if="!isCabinetDisabled">
                        Чтобы сделать/посмотреть предложение, вам нужно
                        <div class="text-center">
                            <DxButton
                                    text="войти"
                                    type="default"
                                    styling-mode="outlined"
                                    @click="openLR('Login')"
                                    class="my-3"
                            />
                        </div>
                        <div class="text-center">
                            или
                        </div>
                        <div class="text-center">
                            <DxButton
                                    text="зарегистрироваться"
                                    type="default"
                                    styling-mode="outlined"
                                    @click="openLR('Registration')"
                                    class="my-3"
                            />
                        </div>
                    </div>
                    <div class="modal-body" v-if="isCabinetDisabled">
                        <p>Для выполнения данных действий Вам требуется пройти регистрацию.</p>
                        <p>По всем вопросам Вы можете связаться с нами по телефону:<br>
                            +7 (727) 312-21-10 или jivochat</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Закрыть</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { isCabinetDisabled } from '../../../environment/environment';
    import headerRoutes from './header-routes';
    import Vue from 'vue';
    import jQuery from 'jquery';

    let $ = jQuery;
    import {mapGetters} from 'vuex';
    import {httpClient} from '../../shared/services';
    import localStore from './store';
    import {Header, Footer} from '../../shared/components';
    import {
        DxForm,
        DxSimpleItem,
        DxLabel,
        DxGroupItem,
        DxColCountByScreen,
        DxButtonItem,
        DxRequiredRule,
        DxEmptyItem
    } from 'devextreme-vue/form';
    import DxButton from 'devextreme-vue/button';
    import {DxLoadIndicator} from 'devextreme-vue/load-indicator';
    import CustomStore from 'devextreme/data/custom_store';
    import moment from 'moment';

    $(function () {

    });

    const storeLocality = new CustomStore({
        key: 'localityId',
        byKey: function () {
            //console.log(key);
            return {};
        },
        load: (loadOptions) => {
            //console.log(loadOptions);

            let searchValue = loadOptions.searchValue != null ? loadOptions.searchValue : '';

            return httpClient.get(`v2/Catalog/Localitys?filter={"localityName":{"op":"con","value":"${searchValue}"}}`)
                .then((data) => {
                    return {
                        key: 'localityId',
                        data: data.data.items,
                        totalCount: data.totals,
                        summary: data.totals,
                        groupCount: data.count,
                        userData: data.data,
                    };
                })
                .catch(() => {
                    throw 'Data Loading Error';
                });
        }
    });

    const storeRollingStockTypes = new CustomStore({
        key: 'rollingStockTypeId',
        byKey: function () {
            //console.log(key);
            return {};
        },
        load: (loadOptions) => {
            //console.log(loadOptions);

            let searchValue = loadOptions.searchValue != null ? loadOptions.searchValue : '';

            let filter = searchValue != '' ? `?filter={"rollingStockTypeName":{"op":"con","value":"${searchValue}"}}` : '';

            return httpClient.get(`v2/Catalog/RollingStockTypes${filter}`)
                .then((data) => {
                    return {
                        key: 'rollingStockTypeId',
                        data: data.data.items,
                        totalCount: data.totals,
                        summary: data.totals,
                        groupCount: data.count,
                    };
                })
                .catch(() => {
                    throw 'Data Loading Error';
                });
        }
    });

    export default {
        name: 'Cargos',
        components: {
            Header,
            Footer,
            DxForm,
            DxSimpleItem,
            DxLabel,
            DxGroupItem,
            DxColCountByScreen,
            DxButton,
            DxLoadIndicator,
            DxButtonItem,
            DxRequiredRule,
            DxEmptyItem
        },
        data() {
            const loadingTypes = ["Задняя", "Боковая", "Верхняя"];

            return {
                headerRoutes,
                isCabinetDisabled,
                routeClassifier: null,
                routeClassifierId: '',
                routeClassifierName: "Все",
                routeClassifierCount: 0,
                groupsCode: null,
                groupCode: '',
                groupCodeName: "Выбрать группу",
                groupCodeCount: 0,
                currencys: null,
                cargos: null,
                skeep: 0,
                take: 6,
                page: 1,
                sortByName: '<strong><i class="fas fa-sort-amount-up"></i> подачи авто</strong>',
                orderField: "pickupDate",
                orderDesc: false,
                advancedSearch: {
                    acn: "",
                    fromLocalityId: "",
                    toLocalityId: "",
                    rollingStockTypeId: "",
                    weightMin: "",
                    weightMax: "",
                    volumeMin: "",
                    volumeMax: "",
                    loadingType: "",
                    pickupDate: ""
                },
                isSetSearch: false,
                offerNew: {
                    rollingStockUnitTypeId: "",
                    amount: "",
                    currencyId: ""
                },
                offerEdit: {
                    rollingStockUnitTypeId: "",
                    amount: "",
                    currencyId: ""
                },
                acnOptions: {
                    placeholder: ""
                },
                fromOptions: {
                    placeholder: 'Откуда',
                    dataSource: storeLocality,
                    displayExpr: 'displayName',
                    valueExpr: 'localityId',
                    value: this.advancedSearch?.fromLocalityId,
                    searchEnabled: true,
                    showDataBeforeSearch: true,
                    showClearButton: true
                },
                toOptions: {
                    placeholder: 'Куда',
                    dataSource: storeLocality,
                    displayExpr: 'displayName',
                    valueExpr: 'localityId',
                    value: this.advancedSearch?.toLocalityId,
                    searchEnabled: true,
                    showDataBeforeSearch: true,
                    showClearButton: true
                },
                weightMinOptions: {
                    placeholder: 'от'
                },
                weightMaxOptions: {
                    placeholder: 'до'
                },
                volumeMinOptions: {
                    placeholder: 'от'
                },
                volumeMaxOptions: {
                    placeholder: 'до'
                },
                loadingTypes,
                labelLocation: 'top',
                buttonSearchOptions: {
                    text: 'Найти',
                    type: 'default',
                    useSubmitBehavior: true
                },
                buttonSearchResetOptions: {
                    text: 'Очистить всё',
                    type: 'default',
                    stylingMode: 'outlined',
                    useSubmitBehavior: false,
                    onClick: this.SearchReset
                },
                rollingStockTypeOptions: {
                    value: this.advancedSearch?.rollingStockTypeId,
                    searchEnabled: true,
                    dataSource: storeRollingStockTypes,
                    displayExpr: 'rollingStockTypeName',
                    valueExpr: 'rollingStockTypeId',
                    showClearButton: true
                },
                storeRollingStockTypeId: "",
                storeRollingStockUnitTypes: new CustomStore({
                    key: 'rollingStockUnitTypeId',
                    byKey: function () {
                        //console.log(key);
                        return {
                            "rollingStockUnitTypeId": window.rollingStockUnitTypeId,
                            "rollingStockUnitTypeName": window.rollingStockUnitTypeName
                        };
                    },
                    load: (loadOptions) => {
                        let searchValue = loadOptions.searchValue != null ? loadOptions.searchValue : '';

                        let filter = searchValue != '' ? `,{"rollingStockUnitTypeName":{"op":"con","value":"${searchValue}"}}` : '';

                        return httpClient.get(`v2/Catalog/RollingStockUnitTypes?filter={"rollingStockTypeId":{"op":"eq","value":"${this.storeRollingStockTypeId}"}}${filter}`)
                            .then((data) => {
                                return {
                                    key: 'rollingStockUnitTypeId',
                                    data: data.data.items,
                                    totalCount: data.totals,
                                    summary: data.totals,
                                    groupCount: data.count,
                                };
                            })
                            .catch(() => {
                                throw 'Data Loading Error';
                            });
                    }
                }),
                isSendOffer: false,
                errorMessageAddOffer: "",
                errorMessageEditOffer: "",
                selectRoute: null,
                selectOffers: null,
                errorMessage: "",
                cargosTotals: 0,
                cargoLotId: this.$route.params.cargoLotId ? this.$route.params.cargoLotId : ''
            }
        },
        mounted() {
            this.getRouteClassifier();
            this.getCurrencys();
            //this.getCargos();
        },
        methods: {
            getRouteClassifier() {
                localStore.dispatch('getRouteClassifier').then(() => {
                    this.routeClassifier = localStore.state.routeClassifier;

                    this.routeClassifierId = this.routeClassifier[0].routeClassifierId;
                    this.routeClassifierName = this.routeClassifier[0].shortName;
                    this.routeClassifierCount = this.routeClassifier[0].count;

                    this.getGroupsCode(this.routeClassifierId);

                    Vue.nextTick(function () {
                        $('[data-toggle="tooltip"]').tooltip({
                            template: '<div class="tooltip bg-white shadow"><div class="tooltip-arrow bg-white shadow"></div><div class="tooltip-inner bg-white"></div></div>',
                            offset: 10
                        })
                    });
                });
            },
            getGroupsCode(routeClassifierId, tokenId = '') {
                if (this.Agent) {
                    tokenId = this.Agent.tokenId;
                }

                localStore.dispatch('getGroupsCode', {tokenId, routeClassifierId}).then(() => {
                    this.groupsCode = localStore.state.groupsCode;
                    let set_first = true;

                    this.groupsCode.forEach(function (itemGroupCode) {
                        if(this.groupCode == itemGroupCode.groupCode) {
                            set_first = false;
                            this.groupCode = itemGroupCode.groupCode;
                            this.groupCodeName = itemGroupCode.groupName;
                            this.groupCodeCount = itemGroupCode.cargoCount;
                        }
                    }, this);

                    if(set_first) {
                        this.groupCode = this.groupsCode[0].groupCode;
                        this.groupCodeName = this.groupsCode[0].groupName;
                        this.groupCodeCount = this.groupsCode[0].cargoCount;
                    }

                    this.getCargos();
                });
            },
            getCurrencys() {
                localStore.dispatch('getCurrencys').then(() => {
                    this.currencys = localStore.state.currencys;
                });
            },
            getCargos(
                tokenId = '',
                routeClassifierId = this.routeClassifierId,
                order_field = this.orderField,
                order_desc = this.orderDesc,
                skeep = this.skeep,
                take = this.take,
                advancedSearch = this.advancedSearch,
                groupCode = this.groupCode
            ) {
                $('html, body').animate({scrollTop:0}, '300');

                this.cargos = null;
                this.cargosTotals = 0;
                this.errorMessage = "";
                let cargoLotId = this.cargoLotId;

                if (this.Agent) {
                    tokenId = this.Agent.tokenId;
                }

                localStore.dispatch('getCargos', {
                    tokenId,
                    routeClassifierId,
                    order_field,
                    order_desc,
                    skeep,
                    take,
                    advancedSearch,
                    groupCode,
                    cargoLotId
                }).then(() => {
                    this.cargos = localStore.state.cargos;
                    this.cargosTotals = this.cargos != null ? this.cargos.totals : 0;
                }).catch(this.handleErrorCargos);
            },
            handleErrorCargos: function (error) {
                if (error.response.status === 400) {
                    if(error.response.data) {
                        let errorMessage = error.response.data;

                        this.errorMessage = errorMessage;
                    } else {
                        this.errorMessage = "Ошибка при поиске лотов. Попробуйте еще раз.";
                    }
                } else if(error.response.status === 500) {
                    if(error.response.data) {
                        let errorMessage = error.response.data;

                        this.errorMessage = JSON.stringify(errorMessage);
                    } else {
                        this.errorMessage = "Ошибка при поиске лотов. Попробуйте еще раз.";
                    }
                } else {
                    this.errorMessage = "Ошибка при поиске лотов. Попробуйте еще раз.";
                }

                this.cargos = [];
                console.error(error);
            },
            getStatus(cargoItem) {
                switch (cargoItem.status) {
                    case 'lotActive':
                        return '<i class="fas fa-hand-holding-usd text-success mr-1"></i>Активная';
                    case 'lotRejected':
                        return '<i class="fas fa-times-circle text-danger mr-1"></i>Аннулирована';
                    case 'lotProcessing':
                        return '<i class="fas fa-eye text-secondary mr-1"></i>На рассмотрении';
                    case 'lotApproved':
                        return '<i class="fas fa-check-square text-success mr-1"></i>Одобрена';
                    case 'lotOfferRejected':
                        return '<i class="fas fa-ban text-danger mr-1"></i>Отклонена';
                    case 'lotClosed':
                        return '<i class="fas fa-lock text-danger mr-1"></i>Закрыта';
                }
                return '- - -';
            },
            selectRouteClassifierItem(routeClassifierId, routeClassifierName, routeClassifierCount) {
                this.routeClassifierId = routeClassifierId;
                this.routeClassifierName = routeClassifierName;
                this.routeClassifierCount = routeClassifierCount;
                if(this.$route.params.cargoLotId) {
                    this.cargoLotId = '';
                    this.$router.replace({ path: '/marketplace/cargos' });
                }
                this.resetParameters();
                this.getGroupsCode(this.routeClassifierId);
            },
            selectGroupsCodeItem(groupCode, groupName, cargoCount) {
                this.groupCode = groupCode;
                this.groupCodeName = groupName;
                this.groupCodeCount = cargoCount;
                this.page = 1;
                this.skeep = 0;
                if(this.$route.params.cargoLotId) {
                    this.cargoLotId = '';
                    this.$router.replace({ path: '/marketplace/cargos' });
                }
                this.getCargos();
            },
            sortBy(sortByName, orderField, sortByIcon, orderDesc) {
                this.sortByName = "";
                this.sortByName = '<strong><i class="'+sortByIcon+'"></i> ' + sortByName + '</strong>';
                this.orderField = orderField;
                this.orderDesc = orderDesc;

                this.getCargos();
            },
            openRoute(cargoLotId, tokenId = '') {
                this.selectRoute = null;

                if (this.Agent) {
                    tokenId = this.Agent.tokenId;
                }

                localStore.dispatch('getRoute', {
                    tokenId,
                    cargoLotId
                }).then(() => {
                    this.selectRoute = localStore.state.route;
                });

                $('#routeModal' + cargoLotId).modal('toggle');
            },
            openOffers(cargoLotId, tokenId = '') {
                this.selectOffers = null;

                if (this.Agent) {
                    tokenId = this.Agent.tokenId;
                }

                localStore.dispatch('getOffers', {
                    tokenId,
                    cargoLotId
                }).then(() => {
                    this.selectOffers = localStore.state.offers;
                });

                $('#offersModal' + cargoLotId).modal('toggle');
            },
            openLR(nameRoute) {
                $('#noLoginModal').modal('toggle');
                this.$router.push({name: nameRoute, query: {redirect: this.$route.path}});
            },
            nextPage() {
                this.skeep = this.skeep + this.take;
                this.getCargos();
            },
            prevPage() {
                this.skeep = this.skeep - this.take;
                if (this.skeep < 0) {
                    this.skeep = 0;
                }
                this.getCargos();
            },
            onSubmitAdvancedSearch() {
                if(this.advancedSearch.acn ||
                    this.advancedSearch.fromLocalityId ||
                    this.advancedSearch.toLocalityId ||
                    this.advancedSearch.rollingStockTypeId ||
                    this.advancedSearch.weightMin ||
                    this.advancedSearch.weightMax ||
                    this.advancedSearch.volumeMin ||
                    this.advancedSearch.volumeMax ||
                    this.advancedSearch.loadingType ||
                    this.advancedSearch.pickupDate
                ) {
                    this.isSetSearch = true;
                } else {
                    this.isSetSearch = false;
                }

                if(this.$route.params.cargoLotId) {
                    this.cargoLotId = '';
                    this.$router.replace({ path: '/marketplace/cargos' });
                }

                this.page = 1;
                this.skeep = 0;
                this.getCargos();
            },
            SearchReset() {
                this.advancedSearch = {
                        acn: "",
                        fromLocalityId: "",
                        toLocalityId: "",
                        rollingStockTypeId: "",
                        weightMin: "",
                        weightMax: "",
                        volumeMin: "",
                        volumeMax: "",
                        loadingType: "",
                        pickupDate: ""
                };

                if(this.$route.params.cargoLotId) {
                    this.cargoLotId = '';
                    this.$router.replace({ path: '/marketplace/cargos' });
                }

                this.isSetSearch = false;
                this.page = 1;
                this.skeep = 0;
                this.getCargos();
            },
            openAdvancedSearch() {
                $('#collapseAdvancedSearch').collapse('toggle');
                this.changeAdvancedSearchChevron();
            },
            changeAdvancedSearchChevron() {

                if($('#advancedSearchChevron').hasClass('fa-chevron-up')) {
                    $('#advancedSearchChevron').removeClass('fa-chevron-up');
                    $('#advancedSearchChevron').addClass('fa-chevron-down');
                } else {
                    $('#advancedSearchChevron').removeClass('fa-chevron-down');
                    $('#advancedSearchChevron').addClass('fa-chevron-up');
                }
            },
            resetParameters() {
                this.page = 1;
                this.skeep = 0;
                this.groupsCode = null;
            },
            isViewTime(pickupDate) {
                return moment(pickupDate).format('HH:mm') != '00:00';
            },
            openAddOfferModal(cargoLotId, rollingStockTypeId) {
                this.storeRollingStockTypeId = rollingStockTypeId;
                this.errorMessageAddOffer = "";
                this.$refs['addOfferFormRef' + cargoLotId][0].instance.resetValues();
                this.isSendOffer = false;
                $('#addOfferModal' + cargoLotId).modal('toggle');
            },
            AddOffer(cargoLotId) {
                this.isSendOffer = true;
                this.errorMessageAddOffer = "";
                let result = this.$refs['addOfferFormRef' + cargoLotId][0].instance.validate();

                if (result.isValid) {
                    const {rollingStockUnitTypeId, amount, currencyId, note} = this.offerNew;

                    httpClient.post(`v2/Marketplace/Cargos/${cargoLotId}/Offers?tokenId=${this.Agent.tokenId}`, {
                        rollingStockUnitTypeId: rollingStockUnitTypeId,
                        amount: amount,
                        currencyId: currencyId,
                        note: note
                    }).then(() => {
                        this.getCargos();
                        $('#addOfferModal' + cargoLotId).modal('toggle');
                        this.errorMessageAddOffer = "";
                    }).catch(this.handleErrorAddOffer);
                } else {
                    this.isSendOffer = false;
                }
            },
            handleErrorAddOffer: function (error) {
                if (error.response.status === 400) {
                    if(error.response.data) {
                        let errorMessage = error.response.data;

                        this.errorMessageAddOffer = errorMessage;
                    } else {
                        this.errorMessageAddOffer = "Ошибка при подаче предложения. Проверьте указанные вами данные и попробуйте еще раз.";
                    }
                } else {
                    this.errorMessageAddOffer = "Ошибка при подаче предложения. Проверьте указанные вами данные и попробуйте еще раз.";
                }

                this.isSendOffer = false;
                console.error(error);
            },
            openEditOfferModal(cargoLotId, rollingStockTypeId, agentOffer) {
                this.storeRollingStockTypeId = rollingStockTypeId;
                this.errorMessageEditOffer = "";
                //let dxsel = this.$refs['editOfferFormRef'+cargoLotId][0].instance.getEditor("rollingStockUnitTypeId");
                window.rollingStockUnitTypeId = agentOffer.rollingStockUnitTypeId;
                window.rollingStockUnitTypeName = agentOffer.rollingStockUnitTypeName;

                this.offerEdit = {
                    rollingStockUnitTypeId: agentOffer.rollingStockUnitTypeId,
                    amount: agentOffer.amount,
                    currencyId: agentOffer.currencyId,
                    note: agentOffer.note
                };

                this.isSendOffer = false;
                $('#editOfferModal' + cargoLotId).modal('toggle');
            },
            EditOffer(cargoLotId, cargoOfferId) {
                this.isSendOffer = true;
                this.errorMessageEditOffer = "";
                let result = this.$refs['editOfferFormRef' + cargoLotId][0].instance.validate();

                if (result.isValid) {
                    const {rollingStockUnitTypeId, amount, currencyId, note} = this.offerEdit;

                    httpClient.post(`v2/Marketplace/Cargos/${cargoLotId}/Offers?tokenId=${this.Agent.tokenId}`, {
                        cargoOfferId: cargoOfferId,
                        rollingStockUnitTypeId: rollingStockUnitTypeId,
                        amount: amount,
                        currencyId: currencyId,
                        note: note
                    }).then(() => {
                        this.getCargos();
                        $('#editOfferModal' + cargoLotId).modal('toggle');
                        this.errorMessageEditOffer = "";
                    }).catch(this.handleErrorEditOffer);
                } else {
                    this.isSendOffer = false;
                }
            },
            handleErrorEditOffer: function (error) {
                if (error.response.status === 400) {
                    if(error.response.data) {
                        let errorMessage = error.response.data;

                        this.errorMessageEditOffer = errorMessage;
                    } else {
                        this.errorMessageEditOffer = "Ошибка при изменении предложения. Проверьте указанные вами данные и попробуйте еще раз.";
                    }
                } else {
                    this.errorMessageEditOffer = "Ошибка при изменении предложения. Проверьте указанные вами данные и попробуйте еще раз.";
                }

                this.isSendOffer = false;
                console.error(error);
            },
            DelOffer(cargoLotId, cargoOfferId) {
                httpClient.delete(`v2/Marketplace/Cargos/${cargoLotId}/Offers/${cargoOfferId}?tokenId=${this.Agent.tokenId}`).then(() => {
                    this.getCargos();
                    $('#editOfferModal' + cargoLotId).modal('toggle');
                    this.errorMessageEditOffer = "";
                }).catch(this.handleErrorDelOffer);
            },
            handleErrorDelOffer: function (error) {
                if (error.response.status === 400) {
                    if(error.response.data) {
                        let errorMessage = error.response.data;

                        this.errorMessageEditOffer = errorMessage;
                    } else {
                        this.errorMessageEditOffer = "Ошибка при удалении предложения. Попробуйте еще раз.";
                    }
                } else {
                    this.errorMessageEditOffer = "Ошибка при удалении предложения. Попробуйте еще раз.";
                }

                this.isSendOffer = false;
                console.error(error);
            },
            clickPagination: function (pageNum) {
                this.skeep = (pageNum - 1) * this.take;
                this.getCargos();
            }
        },
        computed: {
            ...mapGetters([
                'isLogged',
                'Agent'
            ])
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .advanced-search {
        display: none;
    }
</style>
