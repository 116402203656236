export default {
    defaultsoutes: [
        {
            id: 1,
            name: 'Cargos',
            title: 'Грузы',
            hash: ''
        }
    ]
}


