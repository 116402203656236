import Vue from 'vue'
import Vuex from 'vuex'
import {httpClient} from '../../shared/services';
import moment from 'moment';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        routeClassifier: null,
        groupsCode: null,
        currencys: null,
        cargos: null,
        route: null,
        offers: null
    },
    mutations: {
        setRouteClassifier(state, data) {
            state.routeClassifier = data;
        },
        setGroupsCode(state, data) {
            state.groupsCode = data;
        },
        setCurrencys(state, data) {
            state.currencys = data;
        },
        setCargos(state, data) {
            state.cargos = data;
        },
        setRoute(state, data) {
            state.route = data;
        },
        setOffers(state, data) {
            state.offers = data;
        }
    },
    actions: {
        getRouteClassifier({commit}) {
            commit('setRouteClassifier', null);
            return httpClient
                .get('v2/Marketplace/Cargos/route-classifier')
                .then((response) => {
                    commit('setRouteClassifier', response.data);
                }).catch(function (error) {
                    console.error(error);
                });
        },
        getGroupsCode({commit}, {tokenId, routeClassifierId}) {
            commit('setGroupsCode', null);

            let url = `v2/Marketplace/Cargos/route-classifier/${routeClassifierId}/groups`;

            if (tokenId) {
                url = url + `?tokenId=${tokenId}`;
            }

            return httpClient
                .get(url)
                .then((response) => {
                    commit('setGroupsCode', response.data);
                }).catch(function (error) {
                    console.error(error);
                });
        },
        getCurrencys({commit}) {
            commit('setCurrencys', null);
            return httpClient
                .get('v2/Catalog/Currencys')
                .then((response) => {
                    commit('setCurrencys', response.data.items);
                }).catch(function (error) {
                    console.error(error);
                });
        },
        getCargos({commit}, {tokenId, routeClassifierId, order_field, order_desc, skeep, take, advancedSearch, groupCode, cargoLotId}) {
            commit('setCargos', null);
            let url = `v2/Marketplace/Cargos?order_field=${order_field}&order_desc=${order_desc}&skeep=${skeep}&take=${take}`;

            if (tokenId) {
                url = url + `&tokenId=${tokenId}`;
            }

            if (!cargoLotId && routeClassifierId) {
                url = url + `&routeClassifierId=${routeClassifierId}`;
            }

            if (!cargoLotId && groupCode) {
                url = url + `&groupCode=${groupCode}`;
            }

            let filter = '';
            let separator = '';

            if (advancedSearch.acn) {
                filter += `${separator}{"acn":{"op":"eq","value":"${advancedSearch.acn}"}}`;
                separator = ', ';
            }

            if (advancedSearch.fromLocalityId) {
                filter += `${separator}{"firstLocality.localityId":{"op":"eq","value":"${advancedSearch.fromLocalityId}"}}`;
                separator = ', ';
            }

            if (advancedSearch.toLocalityId) {
                filter += `${separator}{"lastLocality.localityId":{"op":"eq","value":"${advancedSearch.toLocalityId}"}}`;
                separator = ', ';
            }

            if (advancedSearch.rollingStockTypeId) {
                filter += `${separator}{"rollingStockTypeId":{"op":"eq","value":"${advancedSearch.rollingStockTypeId}"}}`;
                separator = ', ';
            }

            if (advancedSearch.weightMin) {
                filter += `${separator}{"cargo.weight":{"op":"gte","value":${advancedSearch.weightMin*1000}}}`;
                separator = ', ';
            }

            if (advancedSearch.weightMax) {
                filter += `${separator}{"cargo.weight":{"op":"lte","value":${advancedSearch.weightMax*1000}}}`;
                separator = ', ';
            }

            if (advancedSearch.volumeMin) {
                filter += `${separator}{"cargo.volume":{"op":"gte","value":${advancedSearch.volumeMin}}}`;
                separator = ', ';
            }

            if (advancedSearch.volumeMax) {
                filter += `${separator}{"cargo.volume":{"op":"lte","value":${advancedSearch.volumeMax}}}`;
                separator = ', ';
            }

            if (advancedSearch.loadingType) {
                filter += `${separator}{"cargo.loadingType":{"op":"eq","value":"${advancedSearch.loadingType}"}}`;
                separator = ', ';
            }

            if (advancedSearch.pickupDate) {
                let dateEnd = moment(advancedSearch.pickupDate).add(1, 'days');
                filter += `${separator}{"and":[{"pickupDate":{"op":"gte","value":"${moment(advancedSearch.pickupDate).format('YYYY-MM-DDT00:00:00')}"}}, {"pickupDate":{"op":"lt","value":"${dateEnd.format('YYYY-MM-DDT00:00:00')}"}}]}`;
                separator = ', ';
            }

            if (cargoLotId) {
                filter += `${separator}{"cargoLotId":{"op":"eq","value":"${cargoLotId}"}}`;
                separator = ', ';
            }

            if(filter != '') {
                url = url + `&filter={"and":[${filter}]}`;
            }

            return httpClient
                .get(url)
                .then((response) => {
                    commit('setCargos', response.data);
                });
        },
        getRoute({commit}, {tokenId, cargoLotId}) {
            commit('setRoute', null);
            let url = `v2/Marketplace/Cargos/${cargoLotId}/Route`;

            if (tokenId) {
                url = url + `?tokenId=${tokenId}`;
            }

            return httpClient
                .get(url)
                .then((response) => {
                    commit('setRoute', response.data);
                }).catch(function (error) {
                    console.error(error);
                });
        },
        getOffers({commit}, {tokenId, cargoLotId}) {
            commit('setOffers', null);
            let url = `v2/Marketplace/Cargos/${cargoLotId}/Offers`;

            if (tokenId) {
                url = url + `?tokenId=${tokenId}`;
            }

            return httpClient
                .get(url)
                .then((response) => {
                    commit('setOffers', response.data);
                }).catch(function (error) {
                    console.error(error);
                });
        }
    },
    getters: {},
    modules: {}
});
